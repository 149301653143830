import './App.css';
import Banner from './banner.js'
import Buttons from './button-row.js'


function App() {
  return (
    
    <div className="App">
            <div className='spacer'></div>
            <div class="banner"> <h1>Lucien Zwirtz</h1> </div>
            <div class="content"><p>I create and design experiences that makes peoples lives <b>simple.</b></p></div>  
            <div class="button-div"><Buttons/></div>
    </div>
  );
}

export default App;
