import './button-row.css'


function Buttons(){
    return(
        <div className='button-row'>
        <a>
            <button id="button-one">Portfolio</button>
        </a>
        <a href='mailto:me@lucienzwirt.com'>
            <button id="button-two">Contact</button>
        </a>
        </div>
    )
}

export default Buttons